//
// flatpickr.js
// Theme module
//

import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';

const toggles = document.querySelectorAll('[data-flatpickr]');
flatpickr.localize(French);

toggles.forEach(toggle => {
  const options = toggle.dataset.flatpickr ? JSON.parse(toggle.dataset.flatpickr) : {};

  flatpickr(toggle, options);
});

Livewire.on('nephDateMounted', () => {
    const nephDateEl = document.getElementById('neph-date');

    if(nephDateEl)
        flatpickr(nephDateEl, {'dateFormat': 'd/m/Y', 'locale': 'fr'});
});
