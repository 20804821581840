// Vendor
//import 'bootstrap';
//import { Modal } from 'bootstrap'
import 'alpinejs'
import 'livewire-sortable'
// Theme
import './autosize';
import './checklist';
import './choices';
import './dropdown';
import './dropzone';
import './flatpickr';
import './highlight';
import './inputmask';
import './kanban';
import './list';
import './map';
import './navbar-collapse';
import './popover';
import './tooltip';
import './quill';
import './wizard';

import PerfectScrollbar from 'perfect-scrollbar';


// User
import './user';

const bootstrap = window.bootstrap = require('bootstrap');

window.livewire.on('videoStore', () => {
    window.scrollTo(0, 0);
    var createvideoeModal = document.getElementById('createvideoModalClose');
    createvideoeModal.click();
});

window.livewire.on('videoUpdate', () => {
    window.scrollTo(0, 0);
    var updatevideoeModal = document.getElementById('updatvideoModalClose');
    updatevideoeModal.click();
});

Livewire.on('fuelSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('fuelFormModal'));
    modal.hide();
});

Livewire.on('gearboxSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('gearboxFormModal'));
    modal.hide();
});

Livewire.on('brandCreated', (brandId) => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('brandFormModal'));
    modal.hide();

    setTimeout(() => {
        Livewire.emit('editBrand', brandId);
        modal.show();
    }, 500);
});

Livewire.on('brandUpdated', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('brandFormModal'));
    modal.hide();
});

Livewire.on('stateSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('stateFormModal'));
    modal.hide();
});

Livewire.on('areaSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('areaFormModal'));
    modal.hide();
});

Livewire.on('nephSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('nephFormModal'));
    modal.hide();
});

Livewire.on('moniteurSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('monReqFormModal'));
    modal.hide();
});

Livewire.on('eleveSoldeSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('soldeFormModal'));
    modal.hide();
});

Livewire.on('examineRequestSaved', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('examineInstructorFormModal'));
    modal.hide();
});

Livewire.on('examineDateUpdated', () => {
    const modal = bootstrap.Modal.getInstance(document.getElementById('examenDateUpdate'));
    modal.hide();
});

//
const filterEl = document.querySelector("#filter_eleves .btn-filter, #filter_eleves .apply_filter");
if(filterEl){
    filterEl.addEventListener('click', event => {
        let block = document.querySelector("#filter_eleves .filter-block");

        block.style.display = (block.style.display == "block") ? "none" : "block";
    });
}
