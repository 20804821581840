import EditorJS from '@editorjs/editorjs';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import Header from 'editorjs-header-with-anchor';
import Underline from '@editorjs/underline';
import Code from '@editorjs/code';
import InlineCode from '@editorjs/inline-code';
import Quote from '@editorjs/quote';
import Delimiter  from '@editorjs/delimiter';
import Embed  from '@editorjs/embed';
import LinkTool  from '@editorjs/link';
import Marker  from '@editorjs/marker';
import Warning  from '@editorjs/warning';
import Table  from '@editorjs/table';
import AttachesTool from '@editorjs/attaches';
import RawTool from '@editorjs/raw';
import Alert from 'editorjs-alert';
import Paragraph from 'editorjs-paragraph-with-alignment';
import ColorPlugin from 'editorjs-text-color-plugin';

window.editorInstance = function(dataProperty, editorId, readOnly, placeholder, logLevel) {
    return {
        instance: null,
        data: null,

        init() {
            this.data = this.$wire.get(dataProperty);

            this.instance = new EditorJS({
                holder: editorId,

                readOnly,

                placeholder,

                logLevel,

                tools: {
                    image: {
                        class: ImageTool,
                        config: {
                            uploader: {
                                uploadByFile: (file) => {
                                    return new Promise((resolve) => {
                                        this.$wire.upload(
                                            'uploads',
                                            file,
                                            (uploadedFilename) => {
                                                const eventName = `fileupload:${uploadedFilename.substr(0, 20)}`;

                                                const storeListener = (event) => {
                                                    resolve({
                                                        success: 1,
                                                        file: {
                                                            url: event.detail.url
                                                        }
                                                    });

                                                    window.removeEventListener(eventName, storeListener);
                                                };

                                                window.addEventListener(eventName, storeListener);

                                                this.$wire.call('completedImageUpload', uploadedFilename, eventName);
                                            }
                                        );
                                    });
                                },

                                uploadByUrl: (url) => {
                                    return this.$wire.loadImageFromUrl(url).then(result => {
                                        return {
                                            success: 1,
                                            file: {
                                                url: result
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    },
                    list: {
                        class: List,
                        inlineToolbar: true,
                    },
                    header: {
                        class: Header,
                        inlineToolbar: true,
                    },
                    underline: Underline,
                    code: Code,
                    delimiter: Delimiter,
                    linkTool: LinkTool,
                    warning: Warning,
                    Color: {
                        class: ColorPlugin,
                        config: {
                            colorCollections: ['#FF1300','#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF', '#161C2D'],
                            defaultColor: '#FF1300',
                            type: 'text',
                        }
                    },
                    Marker: {
                        class: Marker,
                        shortcut: 'CMD+SHIFT+M',
                    },
                    embed: {
                        class: Embed,
                        config: {
                          services: {
                            youtube: true,
                            coub: true,
                            codepen: {
                              regex: /https?:\/\/codepen.io\/([^\/\?\&]*)\/pen\/([^\/\?\&]*)/,
                              embedUrl: 'https://codepen.io/<%= remote_id %>?height=300&theme-id=0&default-tab=css,result&embed-version=2',
                              html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%;'></iframe>",
                              height: 300,
                              width: 600,
                              id: (groups) => groups.join('/embed/')
                            }
                          }
                        }
                    },
                    attaches: {
                        class: AttachesTool,
                        config: {
                            endpoint: 'https://admin'+baseurl+'/api/uploade'
                        }
                    },
                    raw: RawTool,
                    table: {
                        class: Table,
                    },
                    paragraph: {
                        class: Paragraph,
                        inlineToolbar: true,
                    },
                    alert: {
                        class: Alert,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+A',
                        config: {
                          defaultType: 'primary',
                          messagePlaceholder: 'Entrez quelque chose',
                        },
                    },
                    'inline-code': InlineCode,
                    quote: Quote
                },

                data: this.data,

                onChange: () => {
                    this.instance.save().then((outputData) => {
                        //this.$wire.set(dataProperty, outputData);
                        var element = document.getElementById('json'+editorId);
                        element.value = JSON.stringify(outputData);
                        element.dispatchEvent(new Event('input'));
                        toHtml(outputData,editorId);
                        //this.$wire.call('save');
                    }).catch((error) => {
                        console.log('Saving failed: ', error)
                    });
                    // console.log('test');
                }
            });
        }
    }
}

function toHtml(json,editorId){
    console.log(json);
    var html = '';

    // init with basic svg icons for alerts
    html += `<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </symbol>
                <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </symbol>
                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </symbol>
            </svg>`;

    json.blocks.forEach(function(block) {
        switch (block.type) {
            case 'header':
                html += `<h${block.data.level} class="fw-bolder">${block.data.text}</h${block.data.level}>`;
                break;
            case 'paragraph':
                var textAlign = 'text-start';
                if(block.data.alignment == 'center')
                    textAlign = 'text-center';
                else if(block.data.alignment == 'right')
                    textAlign = 'text-end';
                    html += `<p class="${textAlign}">${block.data.text}</p>`;
                break;
            case 'delimiter':
                html += '<hr />';
                break;
            case 'image':
                html += `<img class="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em text-muted fs-5 text-center>${block.data.caption}</em>`;
                break;
            case 'list':
                var listStyle;
                if(block.data.style == 'unordered')
                    listStyle = 'ul';
                else if(block.data.style == 'ordered')
                    listStyle = 'ol';
                html += '<' + listStyle + '>';
                block.data.items.forEach(function(li) {
                    html += `<li>${li}</li>`;
                });
                html += '</' + listStyle + '>';
                break;
            case 'table':
                var rows = '', cells;
                block.data.content.forEach( function(row) {
                    cells = '';
                    row.map( function (cell) {
                        cells += `<td class="">${cell}</td>`;
                    });
                    rows += `<tr>${cells}</tr>`;
                });
                html += `<table class="table table-bordered"><tbody>${rows}</tbody></table>`;
                break;
            case 'code':
                html += `<code>${block.data.code}</code>`;
                break;
            case 'linkTool':
                html += `<a href="${block.data.link}">${block.data.link}</a>`;
                break;
            case 'warning':
                html += `<div class="alert alert-warning" role="alert">
                            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                            <span class="alert-heading fs-3">${block.data.title}</span><hr>
                                <p>${block.data.message}</p>
                        </div>`;
                break;
            case 'attaches':
                if(block.data.file.extension == 'mp3'){
                    html += `<audio controls>
                                <source src="/storage/${block.data.file.url}" type="audio/mpeg">
                                Your browser does not support the audio element to play ${block.data.file.name}.
                            </audio>`;
                }else{
                    console.log('Unknown attachment file type', block.data.file.extension);
                    console.log(block);
                }
                break;
            case 'raw':
                html += block.data.html;
                break;
            case 'alert':
                var alertType = block.data.type;
                var svgIcon = '';
                var svgIconInfo = '<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>';
                var svgIconCheck = '<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>';
                var svgIconTriangle = '<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>';
                switch (block.data.type) {
                    case 'primary':     svgIcon = svgIconInfo;      break;
                    case 'secondary':   svgIcon = svgIconInfo;      break;
                    case 'info':        svgIcon = svgIconInfo;      break;
                    case 'success':     svgIcon = svgIconCheck;     break;
                    case 'warning':     svgIcon = svgIconTriangle;  break;
                    case 'danger':      svgIcon = svgIconTriangle;  break;
                    case 'light':       svgIcon = svgIconCheck;     break;
                    case 'dark':        svgIcon = svgIconCheck;     break;
                    default:            svgIcon = '';               break;
                }
                html += `<div class="alert alert-${alertType}" role="alert">${svgIcon} ${block.data.message}</div>`;
                break;
            case 'quote':
                    var alignmentDirection = 'start'
                    if(block.data.alignment == 'center')
                        alignmentDirection = 'center';

                    html += `<figure class="text-start bg-gray-300 pt-2 rounded text-${alignmentDirection}">
                                <blockquote class="blockquote mb-1"><p>${block.data.text}</p></blockquote>
                                <figcaption class="blockquote-footer"><i>- ${block.data.caption}</i></figcaption>
                            </figure>`;
                    break;
            default:
                console.log('Unknown block type', block.type);
                console.log(block);
                break;
        }
    });

    var element = document.getElementById('html'+editorId);
    element.value = html;
    element.dispatchEvent(new Event('input'));
}
