//
// kanban.js
// Dashkit module
//

import { Sortable } from '@shopify/draggable';

const categories = document.querySelectorAll('.kanban-category');
const links = document.querySelectorAll('.kanban-add-link');
const forms = document.querySelectorAll('.kanban-add-form');

function toggleItems(event) {
  const parent = event.currentTarget.closest('.kanban-add');
  const card = parent.querySelector('.card');
  const link = parent.querySelector('.kanban-add-link');
  const form = parent.querySelector('.kanban-add-form');

  link.classList.toggle('d-none');
  form.classList.toggle('d-none');

  if (card && card.classList.contains('card-sm')) {
    if (card.classList.contains('card-flush')) {
      card.classList.remove('card-flush');
    } else {
      card.classList.add('card-flush');
    }
  }
}

const isPrevented = (element, classesToPrevent) => {
  let currentElem = element;
  let isParent = false;
  
  while (currentElem) {
    const hasClass = Array.from(currentElem.classList).some((cls) => classesToPrevent.includes(cls));
    if (hasClass) {
      isParent = true;
      currentElem = undefined;
    } else {
      currentElem = currentElem.parentElement;
    }
  }
  
  return isParent;
}

if (categories) {
  const sortable = new Sortable(categories, {
    draggable: '.kanban-item',
    mirror: {
      constrainDimensions: true
    }
  });
  sortable.on('drag:start', (event) => {
    const currentTarget = event.originalEvent.target;
    
    if (isPrevented(currentTarget, ['checkbox', 'dropdown', 'dropdown-ellipses'])) {
      event.cancel();
    }
  });
}

links.forEach(link => {
  link.addEventListener('click', toggleItems);
});

forms.forEach(form => {
  form.addEventListener('reset', toggleItems);

  form.addEventListener('submit',  function(e) {
    e.preventDefault();
  });
});

//Reset function on livewire update
window.livewire.on('questionsUpdate', () => {
  const links = document.querySelectorAll('.kanban-add-link');
  const forms = document.querySelectorAll('.kanban-add-form');
  links.forEach(link => {
    link.removeEventListener('click', toggleItems);
    link.addEventListener('click', toggleItems);
  });
  forms.forEach(form => {
    form.removeEventListener('reset', toggleItems);
    form.addEventListener('reset', toggleItems);
  });
});